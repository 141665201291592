// images
import TopBackground from "../images/delogue_banner_2560.jpeg";

// icons
import CheckIcon from "../images/icon/check.svg";

export const pageTexts = (t) => {
  return {
    topInfo: {
      bottomDiagonal: true,
      opacity: true,
      image: {
        src: TopBackground,
        alt: t("delogue-x-foursource.delogue"),
        smallHeight: true,
        center: true,
      },
    },
    firstText: [
      {
        text: t("delogue-x-foursource.helpsBuyers"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      {
        text: t("delogue-x-foursource.onlinePlatform"),
        colorClass: "colorDark",
        textType: "paragraph",
        extraClass: "pt-15 font17 lineHeight2",
      },
    ],
    learnButton: {
      text: t("delogue-x-foursource.learnMore"),
      url: "https://www.delogue.com/?utm_source=foursource&utm_medium=landing_page&utm_campaign=foursource_sept",
      type: "mainColor bigWidth",
    },
    why: {
      title: {
        text: t("delogue-x-foursource.whyDelogue"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      separator: true,
    },
    whyList: [
      {
        subtitle: {
          text: t("delogue-x-foursource.shareOnline"),
          colorClass: "colorDark",
          extraClass: "font20 fontWeight600",
          heightClass: "height-80px",
        },
        text: {
          text: t("delogue-x-foursource.singleSource"),
          colorClass: "colorDark",
          textType: "paragraph",
          heightClass: "height-145px",
        },
        icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
        separator: false,
        item: {
          text: [t("delogue-x-foursource.productLevel"), t("delogue-x-foursource.commentSamples"), t("delogue-x-foursource.keepTrack")],
          colorClass: "colorDark",
          noSpacing: true,
        },
      },
      {
        subtitle: {
          text: t("delogue-x-foursource.streamlineProcess"),
          colorClass: "colorDark",
          extraClass: "font20 fontWeight600",
          heightClass: "height-80px",
        },
        text: {
          text: t("delogue-x-foursource.dataFlow"),
          colorClass: "colorDark",
          textType: "paragraph",
          heightClass: "height-145px",
        },
        icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
        separator: false,
        item: {
          text: [t("delogue-x-foursource.optimizeResources"), t("delogue-x-foursource.overviewDeadlines"), t("delogue-x-foursource.createReports")],
          colorClass: "colorDark",
          noSpacing: true,
        },
      },
      {
        subtitle: {
          text: t("delogue-x-foursource.integrateSystems"),
          colorClass: "colorDark",
          extraClass: "font20 fontWeight600",
          heightClass: "height-80px",
        },
        text: {
          text: t("delogue-x-foursource.noNeed"),
          colorClass: "colorDark",
          textType: "paragraph",
          heightClass: "height-145px",
        },
        icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
        separator: false,
        item: {
          text: [t("delogue-x-foursource.reduceMistakes"), t("delogue-x-foursource.saveTime"), t("delogue-x-foursource.transferData")],
          colorClass: "colorDark",
          noSpacing: true,
        },
      },
    ],
    whatCommon: {
      title: {
        text: t("delogue-x-foursource.whatCommon"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      separator: true,
    },
    whatCommonText: [
      {
        text: t("delogue-x-foursource.delogueFoursource"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      {
        text: t("delogue-x-foursource.delogueMakes"),
        colorClass: "colorDark",
        textType: "paragraph",
        extraClass: "pt-15 font17 lineHeight2",
      },
      {
        text: t("delogue-x-foursource.freeIntro"),
        colorClass: "colorDark",
        textType: "paragraph",
        extraClass: "pt-15 font17 lineHeight2",
      },
    ],
    introButton: {
      text: t("delogue-x-foursource.bookIntro"),
      url: "https://www.delogue.com/contact/?utm_source=foursource&utm_medium=landing_page&utm_campaign=foursource_sept",
      type: "mainColor bigWidth",
    },
    introText: [
      {
        text: t("delogue-x-foursource.foursourceUser"),
        colorClass: "colorDark fontWeight600",
        textType: "paragraph",
      },
      {
        text: t("delogue-x-foursource.youWelcome"),
        colorClass: "colorDark",
        textType: "paragraph",
        extraClass: "pt-15 font17 lineHeight2",
      },
    ],
  };
};
