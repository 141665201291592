/**
 * Foursource page
 * https://foursource.com/delogue-x-foursource/
 */

import * as React from "react";
import { withTrans } from "../i18n/withTrans";

import { pageTexts } from "../content/delogue-x-foursource";

// components
import Layout from "../components/layout";
import TextImgBackgroundFull from "../components/textImgBackgroundFull";
import TitleSeparator from "../components/titleSeparator";
import Text from "../components/text";
import ButtonLink from "../components/_partials/_buttonLink";
import BulletsSection from "../components/bulletsSection";

class unsupportedBrowserPage extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.text = pageTexts(this.t);
  }

  render() {
    return (
      <>
        <Layout pageName="delogue-x-foursource" whiteMenu={false}>
          <TextImgBackgroundFull info={this.text.topInfo}></TextImgBackgroundFull>
          <div className="paddingSidesBig pt-30 pb-10">
            <Text text={this.text.firstText}></Text>
            <div className="row justify-content-center pt-30 pb-100">
              <ButtonLink text={this.text.learnButton.text} url={this.text.learnButton.url} type={this.text.learnButton.type}></ButtonLink>
            </div>
          </div>
          <div className="paddingSidesBig backgroundGrey topBottomDiagonal pt-30">
            <div className="pt-100 pb-30">
              <TitleSeparator info={this.text.why}></TitleSeparator>
              <div className="row row-cols-lg-3 row-cols-md-3 row-cols-sm-1 row-cols-1 removeBootstrap">
                {this.text.whyList.map((col, i) => (
                  <BulletsSection key={i} info={col}></BulletsSection>
                ))}
              </div>
            </div>
          </div>
          <div className="paddingSidesBig pt-30 pb-100">
            <TitleSeparator info={this.text.whatCommon}></TitleSeparator>
            <Text text={this.text.whatCommonText}></Text>
            <div className="row justify-content-center pt-30 pb-40">
              <ButtonLink text={this.text.introButton.text} url={this.text.introButton.url} type={this.text.introButton.type}></ButtonLink>
            </div>
            <Text text={this.text.introText}></Text>
          </div>
        </Layout>
      </>
    );
  }
}

export default withTrans(unsupportedBrowserPage);
